import React, { useState } from 'react';

const Card = ({ title, description, image, location }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);


    const handleCardClick = () => {
        setIsModalOpen(true);
        window.scrollTo({
            top: 680,
            behavior: "smooth"
        })
    }

    const handleCloseModal = () => {
        setIsModalOpen(false);
    }

    return (
        <div>
            <article className={isModalOpen ? "card card--modal" : "card"} onClick={handleCardClick}>
                <img className='card__img' src={"/img/galery/" + image} alt={title} />
                <h3 className='card__title'>{title}</h3>
                <p className='card__description'>{description}</p>


            </article>
            {isModalOpen && (
                <div className="modal">
                    <div className="modal__content">
                        <span className="close" onClick={handleCloseModal}>&times;</span>
                        <img className='modal__img' src={"img/galery/" + image} alt={title} />
                        <address className='modal__address'>{location}</address>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Card;
