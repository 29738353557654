import React from 'react';
import Header from '../components/Header'
import Footer from '../components/Footer'
import { NavLink } from 'react-router-dom';
import textjson from '../assets/data/translation/notfound.json'

const text = textjson[0]
const NotFound = ({ actualLang }) => {

    return (
        <div>
            <Header actualLang={actualLang} />
            <main className='notfound'>
                <div className='notfound__container'>
                    <h2 className='notfound__404'>404</h2>
                    <h3 className="notfound__title">{text['title'][actualLang]}</h3>
                    <p className='default-text-style notfound__text'>{text['main-text'][actualLang]}</p>
                    <NavLink to="/">
                        <img
                            className='notfound__escape-door'
                            src="/img/icons/door.png" alt="door icon" />
                    </NavLink>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default NotFound;