import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import textjson from '../assets/data/translation/localisation.json';

const text = textjson[0];
const Localisation = ({ actualLang }) => {

    return (

        <div>
            <Header actualLang={actualLang} />
            <main className='localisation-main'>
                <div className='localisation__map'>
                    <h2 className='localisation__title'>{text['title'][actualLang]}</h2>

                    <p className='localisation__text default-text-style'>{text['main-text'][actualLang]}</p>

                    <address className='localisation__address'> 5 Rue Montmorency, 34120 Pézenas</address>
                    {/* Google Maps HTML */}
                    <div className='localisation__gmap-container'>
                        <iframe title="localisation du musée de la porte"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2895.9501949787177!2d3.4203089761666865!3d43.46163697111184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12b140289bb2b12f%3A0x28eeb50a449ad421!2sMus%C3%A9e%20de%20la%20porte!5e0!3m2!1sfr!2sfr!4v1692809962657!5m2!1sfr!2sfr"
                            width="600" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"
                            className='localisation__gmap'
                        ></iframe>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default Localisation;