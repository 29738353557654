import React, { useEffect, useState } from 'react';
import textjson from '../assets/data/translation/dateshow.json';

const text = textjson[0];

const DateShow = ({ actualLang }) => {




    //Getting date + setting options
    const currentDate = new Date();
    const optionsDate = { weekday: "long", day: "numeric", month: "long" };
    const optionsTime = { hour: "2-digit", minute: "2-digit" };

    //formatting time
    const formattedDate = currentDate.toLocaleDateString('fr-FR', optionsDate);
    let multiFormattedDate = currentDate.toLocaleDateString(text["date"][actualLang], optionsDate);


    const formattedTime = currentDate.toLocaleDateString('fr-FR', optionsTime);
    const parsedTime = formattedTime.split(" ")[1].replace(":", "")

    const isNotMonday = (formattedDate.split(" ")[0] !== "lundi");
    const isWorkingTime = (1530 <= parsedTime && parsedTime < 1830) || (1030 <= parsedTime && parsedTime < 1230);

    let isOpen = (isWorkingTime && isNotMonday);

    const isMorning = (1030 <= parsedTime && parsedTime < 1230);

    const isLunchTime = (1231 <= parsedTime && parsedTime < 1530);
    const isAfterWorkingTime = (1830 < parsedTime);
    const isBeforeWorkingTime = (parsedTime < 1030);

    const nonWorkingMonths = ['octobre', 'novembre', 'décembre', 'janvier', 'février', 'mars', 'avril'];
    const actualMonth = formattedDate.split(" ")[2];
    let openingMessage = null;


    if (nonWorkingMonths.includes(actualMonth)) {
        openingMessage = text['only'][actualLang];
        isOpen = false;

    } else {

        if (!isNotMonday || isAfterWorkingTime) {
            openingMessage = text["tomorrow"][actualLang];
        } else if (isBeforeWorkingTime && isNotMonday) {
            openingMessage = text["today"][actualLang];
        } else if (isLunchTime && isNotMonday) {
            openingMessage = text["later"][actualLang];
        }
    };




    const [isScrollDown, setIsScrollDown] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 200) {
                setIsScrollDown(true);
            } else {
                setIsScrollDown(false);
            }
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll,);
        };
    }, []);



    return (
        <div className={`areweopen ${isScrollDown ? "hiding" : ""}`}>

            <p className='areweopen__regular-text'>{multiFormattedDate}</p>
            {isOpen ?
                (<div>
                    {/* text[thing][actualLang] for text showing */}
                    <p className='open'>
                        {text["open"][actualLang]}
                    </p>
                    <p className='areweopen__regular-text'>
                        {text["until"][actualLang]}
                        {isMorning ? text["then"][actualLang]
                            : "18h30"}</p>
                </div>
                ) :
                (<div>
                    <p className='closed'>
                        {text['closed'][actualLang]}
                    </p>

                    {openingMessage && <p className='areweopen__regular-text'>{openingMessage}</p>}

                </div>)
            }

        </div>
    );
};

export default DateShow;