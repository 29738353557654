import React, { useState } from 'react';

//Chevron import, used to slide images
import galeryChevron from "../assets/images/icons/galery-chevron.svg";
import doors from "../assets/data/doors-db.json";

const Carousel = () => {

    //Getting all images
    const carouselImg = doors;
    const [actualImgIndex, setActualImgIndex] = useState(0);


    return (
        <div className='carousel'>
            <button className="left-chevron"
                onClick={() => {
                    actualImgIndex === 0 ? setActualImgIndex(carouselImg.length - 1) : setActualImgIndex(actualImgIndex - 1)
                }
                } >
                <img className="left-chevron-img" draggable="false"
                    src={galeryChevron}
                    alt="chevron de défilement de la galerie" />
            </button>

            <img
                className='carousel__img' draggable="false"
                src={actualImgIndex >= 0 ? "/img/carousel/" + carouselImg[actualImgIndex].cover : ""}
                alt={actualImgIndex >= 0 ? carouselImg[actualImgIndex].title : ""}
            />


            <button className="right-chevron"
                onClick={() => {
                    const max = carouselImg.length - 1
                    actualImgIndex === max ? setActualImgIndex(0) : setActualImgIndex(actualImgIndex + 1)

                }}
            >
                <img className="right-chevron-img" draggable="false"
                    src={galeryChevron}
                    alt="chevron de défilement de la galerie" />
            </button>
        </div>
    );
};

export default Carousel;