import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";


//? Routes import
import Home from '../pages/Home'
import Contact from "../pages/Contact";
import Localisation from "../pages/Localisation";
import OpeningHours from "../pages/OpeningHours";
import Lore from "../pages/Lore";
import NotFound from "../pages/NotFound";


//? Scroll to top on evey page
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const actualLang = localStorage.getItem("language") ? localStorage.getItem("language") : "fr";

function App() {




  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home actualLang={actualLang} />} />
          <Route path="/Contact" element={<Contact actualLang={actualLang} />} />
          <Route path="/Localisation" element={<Localisation actualLang={actualLang} />} />
          <Route path="Horaires" element={<OpeningHours actualLang={actualLang} />} />
          <Route path="Histoire" element={<Lore actualLang={actualLang} />} />
          <Route path="/*" element={<NotFound actualLang={actualLang} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
