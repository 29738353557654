import React, { useState } from 'react';
import textjson from '../assets/data/translation/contact.json';

const text = textjson[0];
const ContactForm = ({ actualLang }) => {


    const [name, setName] = useState('');
    const [telephone, setTelephone] = useState('');
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const email1 = 'lemuseedelaporte@orange.fr';
    const email2 = 'marioivorra@sfr.fr';

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const subject = encodeURIComponent(`Musée de la Porte : ${name} ${title}`);
        const body = encodeURIComponent(`Nom: ${name} Téléphone: ${telephone}\n\n${message}`);
        const mailtoLink = `mailto:${email1},${email2}?subject=${subject}&body=${body}`;
        window.location.href = mailtoLink;
    };

    return (



        <main className='contact-main'>

            <h2 className='contact-form-title'>{text['title'][actualLang]}</h2>


            <div className='contact-form'>


                <form className='contact-form__container'
                    onSubmit={handleFormSubmit}>

                    <div className='contact-form__name
                    default-contact-box'>
                        {/* //?-------Name------?// */}
                        <label htmlFor="name"
                            className='contact-form__name__label
                            default-text-style'>{text['name'][actualLang]}</label>
                        <input name="name" id="name"
                            className='contact-form__name__input
                            default-text-style
                            default-contact-input'
                            type="text" value={name} onChange={(e) => setName(e.target.value)}
                            required />
                    </div>
                    {/* //?-------Phone-Number------?// */}
                    <div
                        className='contact-form__telephone
                        default-contact-box'>
                        <label htmlFor="phone"
                            className='contact-form__telephone__label
                            default-text-style'>{text['tel'][actualLang]}</label>
                        <input name="phone" id="phone"
                            className='contact-form__telephone__input
                            default-text-style
                            default-contact-input' type="tel" value={telephone}
                            pattern={"[0-9+]*"} maxLength={20}
                            onInput={(e) => {

                                //Prevent letters input
                                const inputValue = e.target.value;
                                const sanitizedValue = inputValue.replace(/[^0-9+]/g, '');
                                if (sanitizedValue !== inputValue) {
                                    e.target.value = sanitizedValue;
                                }
                                //Stop adding characters
                                if (e.target.value.length >= 20) {
                                    e.preventDefault();
                                }
                            }}
                            onChange={(e) => setTelephone(e.target.value)}
                            required />
                    </div>
                    {/* //?------------Subject------------?// */}
                    <div className='contact-form__subject
                    default-contact-box'>
                        <label htmlFor="subject"
                            className='contact-form__subject__label
                            default-text-style'>{text['object'][actualLang]}</label>
                        <input name="subject" id="subject"
                            className='contact-form__subject__input
                            default-text-style
                            default-contact-input' type="subject" value={title} onChange={(e) => setTitle(e.target.value)}
                            required />
                    </div>
                    {/* //?-------Message------?// */}
                    <div className='contact-form__message'>
                        <label htmlFor="message"
                            className='contact-form__message__label
                            default-text-style'>{text['message'][actualLang]}</label>
                        <textarea name="message" id="message"
                            className='contact-form__message__input
                            default-text-style' value={message} onChange={(e) => setMessage(e.target.value)
                            }
                            required />
                    </div>
                    {/* //?-------Submit------?// */}
                    <button className="contact-form__submit" type="submit">{text['submit'][actualLang]}</button>
                </form>
                {/*START------- email and phone number informations ---------START*/}
                <div className='contact-form__foot'>
                    <div className='contact-form__foot__left-box'>
                        <a href="tel:+33788724680">07.88.72.46.80</a>
                    </div>

                    <div className='contact-form__foot__right-box'>
                        <a href="mailto:lemuseedelaporte@orange.fr">lemuseedelaporte@orange.fr</a>
                    </div>
                </div>
                {/*END------- email and phone number informations ---------END*/}
            </div>
        </main>
    );
}

export default ContactForm;