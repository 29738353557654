import React from 'react';
import cardsData from '../assets/data/doors-galery-db.json';
import Card from './Card';

const Galery = ({ actualLang }) => {
    return (
        <div className="gallery">
            {cardsData.map((card, index) => (
                <Card
                    key={card.id + index}
                    id={card.id + index}
                    title={card.title[actualLang]}
                    description={card.description[actualLang]}
                    image={card.cover}
                    location={card.localisation}
                />
            ))}
        </div>
    );
};

export default Galery;