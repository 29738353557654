import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ContactForm from '../components/ContactForm';


const Contact = ({ actualLang }) => {


    return (
        <div className='contact-page'>
            <Header actualLang={actualLang} />
            <ContactForm actualLang={actualLang} />
            <Footer />
        </div>
    );
};

export default Contact;