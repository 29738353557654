import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import OpeningHoursTable from '../components/OpeningHoursTable';

const OpeningHours = ({ actualLang }) => {

    return (
        <div className='opening-hours-page'>
            <Header actualLang={actualLang} />
            <OpeningHoursTable actualLang={actualLang} />
            <Footer />
        </div>
    );
};

export default OpeningHours;