import Header from '../components/Header';
import Carousel from '../components/Carousel';
import Footer from '../components/Footer';
import DateShow from '../components/DateShow';
import textjson from '../assets/data/translation/home.json'

const text = textjson[0]

const Home = ({ actualLang }) => {

    return (
        <div className='home'>
            <Header actualLang={actualLang} />
            <main className='home-main'>
                {(actualLang === "fr") ?
                    <h2 className='home-main__title'>
                        Franchissez les <strong>Portes</strong> de la Découverte !
                    </h2> :
                    <h2 className='home-main__title'>{text['title'][actualLang]}</h2>
                }

                <DateShow actualLang={actualLang} />
                <Carousel />
                <span className='author'>{text['author'][actualLang]}: Diane Bouteiller</span>
                {(actualLang === "fr") ?
                    <p className='home-main__text default-text-style'>Une invitation à travers le temps et l'esthétique. Le <strong>Musée de la Porte</strong>, fondé par monsieur Serge Ivorra, vous convie à une exploration unique de l'évolution artistique et culturelle des portes à travers les différentes époques.
                        Chaque porte exposée est bien plus qu'un simple élément fonctionnel, elle incarne une époque, un style et une narration propre. De l'élégance intemporelle classique à l'audace expérimentale du design contemporain, notre collection met en lumière la richesse de l'ingéniosité humaine.</p> :
                    <p className='home-main__text default-text-style'>{text['main-text'][actualLang]}</p>
                }
                <p className='default-text-style bold'>{text['secondary-text'][actualLang]}</p>
            </main>
            <Footer />
        </div>
    );
};

export default Home;