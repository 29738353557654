import React, { useState } from 'react';
import openingHoursData from '../assets/data/opening-hours.json';
import textjson from '../assets/data/translation/openinghours.json';

const text = textjson[0];

const summerOpeningHours = openingHoursData[0];
const winterOpeningHours = openingHoursData[1];

const OpeningHoursTable = ({ actualLang }) => {


    let joursSemaine = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
    let months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
    //It's not actually "summer"...
    let summer = ['may', 'jun', 'jul', 'aug', "sep"];



    //?------------catching and formating dates-----------?//
    const currentDate = new Date();
    const optionsDate = { weekday: "long", day: "numeric", month: "short" };
    const formattedDate = currentDate.toLocaleDateString('en-US', optionsDate);
    const actualMonth = formattedDate.split(" ")[1].toLocaleLowerCase();

    const [selectedMonth, setSelectedMonth] = useState(actualMonth);
    const [selectedSeason, setSelectedSeason] = useState(summer.includes(actualMonth) ? "summer" : "winter");

    //?-------------------Changing schedule after selecting a month-----------?//
    const handleMonthChange = (event) => {
        const newSelectedMonth = event.target.value;
        setSelectedMonth(newSelectedMonth);
        setSelectedSeason(summer.includes(newSelectedMonth) ? "summer" : "winter");
    };




    return (

        <main className='opening-hours-main'>
            <div className="hours-table">
                <h2 className='hours-table__title'>{text['title'][actualLang]}</h2>
                {/* month selector */}
                <div className="month-selector">
                    <label className='month-selector__label'>{text['label'][actualLang]}</label>
                    <select className='selected-month'
                        value={selectedMonth} onChange={handleMonthChange}>
                        {months.map((month) => (
                            <option className='month-option'
                                key={`opt-${month}`}
                                value={month}>{text["months"][month][actualLang]}</option>
                        ))}
                    </select>
                </div>
                {/* schedule */}
                <table className='hours-table__table'>
                    <thead>
                        <tr>
                            <th>{text['day'][actualLang]}</th>
                            <th>{text['morning'][actualLang]}</th>
                            <th>{text['afternoon'][actualLang]}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {joursSemaine.map(jour => (
                            <tr className='' key={jour}>
                                <td className='opening-day'>{text[jour][actualLang]}</td>
                                <td>{

                                    selectedSeason === "summer" ?

                                        summerOpeningHours.hours[jour].morning === "RDV" ?
                                            text['only'][actualLang]
                                            : summerOpeningHours.hours[jour].morning === "closed" ? text['closed'][actualLang] :
                                                summerOpeningHours.hours[jour].morning

                                        : winterOpeningHours.hours[jour].morning === "RDV" ?
                                            text['only'][actualLang]
                                            : winterOpeningHours.hours[jour].morning === "closed" ? text['closed'][actualLang] :
                                                winterOpeningHours.hours[jour].morning
                                }
                                </td>

                                <td>{

                                    selectedSeason === "summer" ?

                                        summerOpeningHours.hours[jour].after === "RDV" ?
                                            text['only'][actualLang]
                                            : summerOpeningHours.hours[jour].after === "closed" ? text['closed'][actualLang] :
                                                summerOpeningHours.hours[jour].after

                                        : winterOpeningHours.hours[jour].after === "RDV" ?
                                            text['only'][actualLang]
                                            : winterOpeningHours.hours[jour].after === "closed" ? text['closed'][actualLang] :
                                                winterOpeningHours.hours[jour].after

                                }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </main>
    );
}

export default OpeningHoursTable;
