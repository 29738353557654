import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import textjson from '../assets/data/translation/header.json';
import LanguageSwitch from './LanguageSwitch';

const text = textjson[0];



const Header = ({ actualLang }) => {

    //* Scrolling listener *//

    const [isScrollDown, setIsScrollDown] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsScrollDown(true);
            } else {
                setIsScrollDown(false);
            }
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll,);
        };
    }, []);

    // free museum sticker => hiding function
    const [isStickerHidden, setIsStickerHidden] = useState(
        localStorage.getItem('isStickerHidden') === 'true'
    );

    const handleToggleSticker = () => {
        const newIsStickerHidden = !isStickerHidden;
        setIsStickerHidden(newIsStickerHidden);
        localStorage.setItem('isStickerHidden', newIsStickerHidden);
    }




    return (


        <header className={`header ${isScrollDown ? 'mini-header' : 'maxi-header'}`}>
            <NavLink to="/">
                {/* title */}
                <h1
                    className={`header__title ${isScrollDown ? 'mini-title' : 'maxi-title'}`}>
                    Le <strong>Musée de la Porte</strong>
                </h1>
            </NavLink>
            <div className='header__line'></div>
            {/* Free museum sticker */}
            <span className={`sticker ${isStickerHidden ? 'hide-sticker' : ''}`} onClick={handleToggleSticker}>
                {text['free'][actualLang]}
            </span>


            <LanguageSwitch isScrollDown={isScrollDown} />

            <nav className='header__nav'>
                <ul className='header__nav-list'>
                    {/* Lore-nav */}
                    <li
                        className={`header__nav-list__element ${isScrollDown ? "mini-element" : ""}`}>
                        <NavLink
                            to="/histoire"
                            className={(nav) => (nav.isActive ? "nav-active" : "")}>
                            {text['gallery'][actualLang]}
                        </NavLink>
                    </li>
                    {/* location-nav */}
                    <li
                        className={`header__nav-list__element ${isScrollDown ? "mini-element" : ""}`}>
                        <NavLink
                            to="/localisation"
                            className={(nav) => (nav.isActive ? "nav-active" : "")}>
                            {text['location'][actualLang]}
                        </NavLink>
                    </li>
                    {/* opening-hours-nav */}
                    <li
                        className={`header__nav-list__element ${isScrollDown ? "mini-element" : ""}`}>
                        <NavLink
                            to="/horaires"
                            className={(nav) => (nav.isActive ? "nav-active" : "")}>
                            {text['opening'][actualLang]}
                        </NavLink>
                    </li>
                    {/* contact-nav */}
                    <li
                        className={`header__nav-list__element ${isScrollDown ? "mini-element" : ""}`}>
                        <NavLink
                            to="/contact"
                            className={(nav) => (nav.isActive ? "nav-active" : "")}>
                            {text['contact'][actualLang]}
                        </NavLink>
                    </li>

                </ul>
            </nav>
        </header>
    );
};

export default Header;
