import React from 'react';

const Footer = () => {
    return (
        <footer className='footer'>

            <div className='footer__left-box'>
                <img className='footer__contact-icon'
                    src="/img/icons/phone.png" alt="phone icon" />
                <a href="tel:+33788724680">07.88.72.46.80</a>
            </div>

            <div className='footer__center-box'>
                <img
                    className='footer__door-icon'
                    src="/img/icons/door.png" alt="door icon" />

                <ul className="footer__list">
                    <li><a target="_blank" href="https://www.flaticon.com/authors/freepik" title="magic icons">Flags & door icons created by Freepik</a></li>
                    <li><a target="_blank" href="https://www.flaticon.com/authors/th-studio" title="right chevron icons">Chevron icon created by th studio</a></li>
                    <li><a target="_blank" href="https://www.flaticon.com/free-icons/instagram" title="instagram icons">FB and IG icons created by edt.im</a></li>
                    <li><a target="_blank" href='https://github.com/JoyfulCanis'>Website created by Joyful Canis</a></li>
                </ul>

                <div className='footer__icons-container'>
                    <img className="footer__icons-container__icon" src="/img/icons/facebook.png" alt="facebook logo" />
                    <a href="https://www.ville-pezenas.fr/" target="_blank"><img className="footer__icons-container__icon" src="/img/icons/pezenas.png" alt="pezenas shield" /></a>
                    <img className="footer__icons-container__icon" src="/img/icons/instagram.png" alt="instagram logo" />
                </div>
            </div>

            <div className='footer__right-box'>
                <img className='footer__contact-icon'
                    src="/img/icons/arroba.png" alt="arroba icon" />
                <a href="mailto:lemuseedelaporte@orange.fr">lemuseedelaporte@orange.fr</a>
            </div>
        </footer>
    );
};

export default Footer;