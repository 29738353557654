import React, { useEffect, useState } from 'react';
import galeryChevron from "../assets/images/icons/galery-chevron.svg";


const LanguageSwitch = ({ isScrollDown }) => {


    //* Language listener *//
    const languagesMap = ["fr", "en", "ru", "es", "de", "it"]
    const localLanguage = localStorage.getItem("language");
    const currentState = localLanguage ? localLanguage : "fr";
    const [selectedLanguage, setSelectedLanguage] = useState(currentState);

    const handleLanguageChange = (event) => {
        const newSelectedLanguage = event.target.id;

        setSelectedLanguage(newSelectedLanguage);
        localStorage.setItem("language", newSelectedLanguage);
        window.location.reload();
    };

    const [isDeployed, setIsDeployed] = useState(false);

    const handleToggleLanguageList = () => {
        const newStateOfDeployed = !isDeployed;
        setIsDeployed(newStateOfDeployed);

    }
    useEffect(() => {
        if (isScrollDown) {
            setIsDeployed(false);
        }
    }, [isScrollDown]);
    return (
        <div className={`selected-language  ${isScrollDown ? "hidden-selected" : ""}`}>

            <button className={`language-button`} onClick={handleToggleLanguageList}>
                <img className={`flag main-flag ${isDeployed ? "selected-flag" : ""}`}

                    alt={`flag ` + selectedLanguage}
                    src={`/img/icons/flags/${selectedLanguage}.png`} />

                <img src={galeryChevron} alt="chevron" className={`language-chevron ${isDeployed ? "chevron-up" : ""}`} />
            </button>



            <div className={`language-list ${isDeployed ? "" : "hidden-list"}`}>
                {languagesMap.map((lang) => (
                    <img className='flag'
                        key={"flag" + lang}
                        id={lang}
                        onClick={handleLanguageChange}
                        alt={`drapeau ` + lang} src={`/img/icons/flags/${lang}.png`} />
                ))}
            </div>
        </div>
    );
};

export default LanguageSwitch;