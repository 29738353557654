import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Galery from '../components/Galery';
import textjson from '../assets/data/translation/lore.json'

const text = textjson[0]

const Lore = ({ actualLang }) => {

    return (
        <div>
            <Header actualLang={actualLang} />
            <main className='lore-main'>

                {actualLang === "fr" && (
                    <h2 className='lore-main__title'>
                        Aperçu de notre <strong>Collection de Portes</strong>
                    </h2>
                )}
                {actualLang !== "fr" && (
                    <h2 className='lore-main__title'>
                        {text['title'][actualLang]}
                    </h2>
                )}



                <p className='default-text-style lore-main__text' >
                    {text['main-text'][actualLang][1]}
                    <br /><br />
                    {text['main-text'][actualLang][2]}
                    <br /><br />
                    {text['main-text'][actualLang][3]}
                </p>
                <Galery actualLang={actualLang} />
            </main>
            <Footer />
        </div >
    );
};

export default Lore;